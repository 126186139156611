import { Dispatch, FC, SetStateAction } from "react";

interface Props {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const LogoEB: FC<Props> = ({ collapsed, setCollapsed }) => {
  return (
    <div
      onClick={() => setCollapsed((prev) => !prev)}
      style={{ cursor: "pointer" }}
    >
      {collapsed ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="61"
          viewBox="0 0 100 69"
          fill="none"
        >
          <path
            d="M29.7747 40.0444C29.9044 43.2118 31.6752 45.3086 34.7858 45.3086C36.557 45.3086 38.2849 44.461 38.976 42.7658H45.8444C44.5053 48.1191 39.7104 50.4393 34.699 50.4393C27.3985 50.4393 22.4306 45.8888 22.4306 38.1266C22.4306 31.0333 27.9167 25.9478 34.5264 25.9478C42.5612 25.9478 46.5788 32.1934 46.2332 40.0448H29.7747V40.0444ZM38.8899 35.8064C38.7602 33.2189 36.9894 31.0777 34.5267 31.0777C31.9347 31.0777 30.2068 32.9959 29.7751 35.8064H38.8899Z"
            fill="#FF7705"
          />
          <path
            d="M48.2542 18.0039H62.9453C68.3558 17.9133 74.2938 19.3667 74.2938 26.1354C74.2938 29.0426 72.6223 31.4049 70.1593 32.677C73.5023 33.6765 75.5694 36.6747 75.5694 40.3543C75.5694 48.077 70.0709 50.4393 63.3413 50.4393H48.2538V18.0039H48.2542ZM56.436 30.769H62.7701C64.1776 30.769 66.1127 29.9966 66.1127 27.6798C66.1127 25.3175 64.4852 24.5455 62.7701 24.5455H56.436V30.769ZM56.436 43.6702H62.9901C65.5855 43.6702 67.3886 42.7162 67.3886 40.0361C67.3886 37.1745 65.4531 36.2201 62.9901 36.2201H56.436V43.6702Z"
            fill="#53358E"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="61"
          viewBox="0 0 116 28"
          fill="none"
        >
          <path
            d="M5.76992 18.8701C5.87196 21.3748 7.26319 23.0327 9.70693 23.0327C11.0986 23.0327 12.4563 22.3623 12.9991 21.022H18.3954C17.3433 25.2549 13.5759 27.0892 9.6389 27.0892C3.90299 27.0892 0 23.491 0 17.353C0 11.7441 4.3102 7.72278 9.50332 7.72278C15.8165 7.72278 18.9726 12.6613 18.701 18.8701H5.76992ZM12.9311 15.5187C12.829 13.4728 11.4378 11.7793 9.50332 11.7793C7.46679 11.7793 6.10911 13.296 5.76992 15.5187H12.9311Z"
            fill="#FF7705"
          />
          <path
            d="M20.2261 8.25195H25.8264V10.7566H25.8944C26.9465 8.88715 29.3222 7.72278 31.4267 7.72278C37.2982 7.72278 37.7735 12.1677 37.7735 14.8131V26.56H32.0035V17.7062C32.0035 15.2016 32.2752 12.6617 29.1186 12.6617C26.9465 12.6617 25.996 14.5664 25.996 16.5775V26.5605H20.2261V8.25195Z"
            fill="#FF7705"
          />
          <path
            d="M45.1735 18.8705C45.2755 21.3752 46.6667 23.0331 49.1105 23.0331C50.5022 23.0331 51.8599 22.3627 52.4027 21.0224H57.7989C56.7469 25.2553 52.9795 27.09 49.0425 27.09C43.3065 27.09 39.4036 23.4919 39.4036 17.3538C39.4036 11.745 43.7138 7.72363 48.9069 7.72363C55.2201 7.72363 58.3762 12.6621 58.1046 18.871H45.1735V18.8705ZM52.3351 15.5191C52.2331 13.4732 50.8418 11.7797 48.9074 11.7797C46.8708 11.7797 45.5136 13.2964 45.174 15.5191H52.3351Z"
            fill="#FF7705"
          />
          <path
            d="M59.8342 8.25098H65.4346V11.4612H65.5026C66.351 9.13293 68.3195 7.89819 70.7973 7.89819C71.2385 7.89819 71.7137 7.93338 72.155 8.0394V13.5071C71.4081 13.2955 70.7292 13.1543 69.9488 13.1543C67.0979 13.1543 65.6046 15.2001 65.6046 17.4932V26.5591H59.8347V8.25098H59.8342Z"
            fill="#FF7705"
          />
          <path
            d="M73.0078 0.913005H84.5505C88.8012 0.841212 93.4671 1.99085 93.4671 7.34296C93.4671 9.64175 92.1538 11.5098 90.2184 12.5154C92.845 13.3056 94.4691 15.6766 94.4691 18.5859C94.4691 24.6925 90.149 26.5606 84.8618 26.5606H73.0078V0.913005ZM79.4358 11.0068H84.4126C85.5185 11.0068 87.0387 10.3958 87.0387 8.56391C87.0387 6.69588 85.7599 6.0854 84.4126 6.0854H79.4358V11.0068ZM79.4358 21.2085H84.585C86.6239 21.2085 88.0411 20.4539 88.0411 18.3348C88.0411 16.0717 86.5204 15.3177 84.585 15.3177H79.4358V21.2085Z"
            fill="#53358E"
          />
          <path
            d="M102.419 5.58245H96.5447V0.912598H102.419V5.58245ZM96.5447 7.91737H102.419V26.5606H96.5447V7.91737Z"
            fill="#53358E"
          />
          <path
            d="M116.001 11.8692H112.372V20.3464C112.372 21.8193 113.201 22.1422 114.48 22.1422C114.963 22.1422 115.482 22.0704 116 22.0704V26.5605C114.929 26.5962 113.858 26.7402 112.786 26.7402C107.775 26.7402 106.497 25.2316 106.497 20.1667V11.8692H103.524V7.91773H106.497V2.27844H112.371V7.9182H116V11.8692H116.001Z"
            fill="#53358E"
          />
        </svg>
      )}
    </div>
  );
};
