import { ThemeConfig } from "@enerbit/base";
import NavSidebar from "./common/components/NavSidebar";

export default function Root(props) {
  return (
    <ThemeConfig>
      <NavSidebar />
    </ThemeConfig>
  );
}
