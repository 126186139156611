/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
/* eslint-disable no-console */

import {
  decodedJwt,
  JwtDecode,
  LogoAccountSidebar,
  LogoutIcon,
  ModalLogout,
  NotificationsDrawer,
  NotificationsNoneRoundedIcon,
  NotificationToast,
  useNotificationStore,
} from "@enerbit/base";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { CustomSideBar } from "./CustomSideBar";
const NavSidebar = () => {
  const [user, setUser] = useState<JwtDecode>();
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);
  const createClient = useRef<any>(null);

  const { hasNewNotification, setHasNewNotification } = useNotificationStore();

  const callCreateClient = () => {
    createClient.current.changeModal();
  };

  const getUsername = () => {
    const authInfo = localStorage.getItem("authInfo");
    if (authInfo) {
      const username = JSON.parse(authInfo);
      return username?.email;
    }
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  useEffect(() => {
    const decode = decodedJwt();
    setUser(decode);
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" color="primary" sx={{ height: "70px" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
            <Box>
              <IconButton color="inherit" onClick={handleNotificationsOpen}>
                <Badge
                  color="secondary"
                  variant="dot"
                  overlap="circular"
                  invisible={!hasNewNotification}
                >
                  <NotificationsNoneRoundedIcon />
                </Badge>
              </IconButton>
            </Box>
            <Box className="Icon-container">
              <LogoAccountSidebar />
            </Box>
            <Typography
              className="Text-name-user"
              variant="subtitle1"
              noWrap
              component="div"
            >
              {getUsername()}
            </Typography>
            <IconButton color="inherit" onClick={callCreateClient}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!(user?.role === "contratista") && <CustomSideBar />}
      </Box>
      <ModalLogout ref={createClient} />
      <ModalLogout ref={createClient} />
      <NotificationToast />
      <NotificationsDrawer
        open={openNotifications}
        handleClose={() => {
          setOpenNotifications(false);
          setHasNewNotification(false);
        }}
      />
    </>
  );
};

export default NavSidebar;
