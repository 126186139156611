import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import * as singleSpa from "single-spa";

import {
  Menu as ProMenu,
  MenuItem as ProMenuItem,
  Sidebar as ProSidebar,
  SubMenu as ProSubMenu,
} from "react-pro-sidebar";
import { menuItems } from "../../const/menuItems";
import { useCurrentPath } from "../hooks/useCurrentPath";
import { ICustomSidebar } from "../models/ICustomSideBar";
import { LogoEB } from "./LogoEB";

export const CustomSideBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const currentPath = useCurrentPath();

  const isAnySubMenuItemActive = (subMenuItems: ICustomSidebar[]) => {
    return subMenuItems.some(({ url }) => url?.includes(window.location.hash));
  };

  return (
    <ProSidebar
      style={{
        height: "100vh",
        zIndex: 1200,
        boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
        position: "fixed",
        left: 0,
        top: 0,
      }}
      backgroundColor="#ffffff"
      collapsed={collapsed}
      toggled={false}
    >
      <ProMenu>
        <Box
          sx={{
            padding: !collapsed ? "0px 20px" : "0px",
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}
        >
          <LogoEB collapsed={collapsed} setCollapsed={setCollapsed} />
          {!collapsed && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => setCollapsed((prev) => !prev)}
                size="small"
                sx={{ backgroundColor: "#F2F4F7" }}
              >
                <ArrowBackIosNew fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider sx={{ mb: "22px" }} />
        {menuItems.map(({ icon, url, title, children }, index) => {
          return (
            <>
              {children ? (
                <Tooltip title={title} placement="right" key={index}>
                  <ProSubMenu
                    label={title}
                    icon={icon}
                    active={isAnySubMenuItemActive(children)}
                    rootStyles={{
                      [`&.ps-active > a`]: {
                        backgroundColor: "#EEEBF4",
                        fill: "#53358E!important",
                        color: "#53358E!important",
                        fontWeight: "bold",
                      },
                      [`&.ps-active > a:hover`]: {
                        backgroundColor: "#EEEBF4",
                      },
                      [`&.ps-menuitem-root > a > .ps-submenu-expand-icon`]: {
                        display: collapsed ? "none" : "",
                      },
                    }}
                  >
                    {children?.map(({ icon, url, title }, subIndex) => {
                      return (
                        <Tooltip
                          title={title}
                          placement="right"
                          key={`${index}-${subIndex}`}
                        >
                          <ProMenuItem
                            key={index}
                            icon={icon}
                            active={url?.includes(currentPath)}
                            onClick={() => {
                              url ? singleSpa.navigateToUrl(url) : null;
                            }}
                            rootStyles={{
                              [`&.ps-active > a`]: {
                                backgroundColor: "#EEEBF4",
                                fontWeight: "bold",
                                borderLeft: "3px solid #53358E",
                                fill: "#53358E!important",
                                color: "#53358E!important",
                              },
                              [`&.ps-active > a:hover`]: {
                                backgroundColor: "#EEEBF4",
                              },
                            }}
                          >
                            {title}
                          </ProMenuItem>
                        </Tooltip>
                      );
                    })}
                  </ProSubMenu>
                </Tooltip>
              ) : (
                <Tooltip title={title} placement="right" key={index}>
                  <ProMenuItem
                    icon={icon}
                    active={url?.includes(window.location.hash)}
                    onClick={() => {
                      url ? singleSpa.navigateToUrl(url) : null;
                    }}
                    rootStyles={{
                      [`&.ps-active > a`]: {
                        backgroundColor: "#EEEBF4",
                        fill: "#53358E!important",
                        color: "#53358E",
                        fontWeight: "bold",
                      },
                      [`&.ps-active > a:hover`]: {
                        backgroundColor: "#EEEBF4",
                      },
                    }}
                  >
                    {title}
                  </ProMenuItem>
                </Tooltip>
              )}
            </>
          );
        })}
      </ProMenu>
    </ProSidebar>
  );
};
